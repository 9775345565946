<script lang="ts">
import { type PropType } from "vue";
interface Source {
    type: "multicolour";
    text: string;
    multicolourClass?: string;
}
// let props = defineProps<{ value: string }>();
export default defineComponent({
    props: {
        value: {
            type: [String, Array] as PropType<string | Source[]>,
            default: () => null,
        },
    },
    setup(props) {
        let isText = computed(() => {
            return typeof props.value === "string";
        });

        //如果是文本直接选入
        if (isText.value) return () => props.value;
        const children = computed(() => {
            let { value } = props;
            if (Array.isArray(value)) {
                return value.map((item) => {
                    if (typeof item === "string") {
                        return item;
                    } else {
                        const compnents = {
                            multicolour: (value: string) => h("span", { x: item.multicolourClass || "text-gradient", class: item.multicolourClass || "text-gradient" }, value),
                        };
                        return compnents["multicolour"](item.text);
                    }
                });
            }
            return "";
        });

        return () => h("span", null, [children.value]);
    },
});
</script>
